<template>
  <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->
  <header>
    <div class="hero">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <img class="hero-logo" alt="TAXI PLUS ŁOMŻA" src="./assets/taxipluslomza.svg" />
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->
  <div class="contact">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>Zadzwoń</h2>
          <h1 class="contact-phone">86 444 1000</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="mt-4 mb-4">Lub skorzystaj z aplikacji</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 contact-app">
          <a
            class="btn btn-warning btn-app app-browser btn-lg"
            href="https://ztaxi.pl/tpl"
            target="_blank"
          >
            <h4>w przeglądarce</h4>
          </a>
          <a
            class="btn btn-warning btn-app app-phone btn-lg"
            href="https://play.google.com/store/apps/details?id=pl.systemtaxi.client.taxi.plus.lomza&gl=PL"
            target="_blank"
          >
            <h4>w telefonie</h4>
            <img
              class="google-play-badge"
              alt="TAXI PLUS ŁOMŻA"
              src="./assets/google-play-badge.svg"
            />
          </a>
        </div>
      </div>
      <div></div>
    </div>
  </div>
  <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->

  <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->
  <main>
    <div class="container">
      <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->
      <div class="row main-slogan">
        <div class="col-12">
          <h2>KULTURA, SOLIDNOŚĆ, DYSKRECJA</h2>
        </div>
      </div>

      <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->
      <div class="row justify-content-md-center main-services">
        <div class="col-12 col-md-5">
          <ul class="list-group list-group-flush list-info">
            <li class="list-group-item">Obsługa imprez</li>
            <li class="list-group-item">bezpłatny dojazd do klienta na terenie miasta</li>
            <li class="list-group-item">zakupy 24h</li>
            <li class="list-group-item">odprowadzanie samochodu</li>
            <li class="list-group-item">bezpieczny dowóz dzieci</li>
            <li class="list-group-item">pomoc w uruchomieniu auta</li>
          </ul>
        </div>
      </div>

      <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->
      <div class="row main-facebook">
        <div class="col-12">
          <a href="https://www.facebook.com/Lomzataxiplus/" target="_blank"
            ><font-awesome-icon :icon="['fab', 'facebook']"
          /></a>
        </div>
      </div>

      <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->
      <div class="row">
        <div class="col-12">
          <img class="main-lomza" alt="ŁOMŻA" src="./assets/lomza.svg" />
        </div>
      </div>
    </div>
  </main>

  <!-- :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: -->
  <footer class="footer mt-auto py-4">
    <div class="container">
      <span class="text-muted">TAXI PLUS ŁOMŻA &copy; 2022</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@800&display=swap");
@import "./scss/main.scss";
</style>
