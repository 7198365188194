import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/scss/bootstrap.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
//import { faMeh } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTaxi } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebook)
library.add(faTaxi)

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
